import '../common/js/common';
import eventBus from '../common/js/event-bus/event-bus';
import { mediaQueries } from '../common/js/media-query/media-query';
import exchangeMarzs from '../common/components/exchange-marzs/exchange-marzs.vue';
import Vue from 'vue';
import store from '../common/js/vuex/store';
import announcementStore from '../common/components/announcement/announcement-vuex';
import announcement from '../common/components/announcement/announcement.vue';
import VisibilityContainer from '../common/components/visibility-container/visibility-container';
import mher from '../modules/MHER/mher.vue';
import i18n from '../common/js/lang/i18n';
import { useDirektIbCookieHandler } from '../common/js/composables/direkt-ib-cookie-handler';
import { usePersonalization } from '../common/js/composables/personalization';
import PortalVue from 'portal-vue';

import { register } from 'swiper/element/bundle';
register();

Vue.use(PortalVue);
Vue.component('exchange-marzs', exchangeMarzs);
Vue.component('visibility-container', VisibilityContainer);
Vue.component('mher', mher);
if (!store.hasModule(['announcements'])) {
	store.registerModule('announcements', announcementStore);
}
if (window.context?.announcements?.length) {
	store.dispatch('announcements/getAnnouncements', window.context.announcements);
	Vue.component('announcement', announcement);
}
export default {
	i18n,
	mediaQueries,
	data: {
		ariaHide: false,
		modalVisible: null,
		allowAnchor: true,
		heroTitle: null
	},
	setup() {
		const { provideDirektEbValues } = useDirektIbCookieHandler();
		usePersonalization();
		provideDirektEbValues();
	},
	store,
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	methods: {
		waitForFonts() {
			if (location.hash) {
				let wait = setInterval(() => {
					if (document.documentElement.classList.contains('wf-active')) {
						var elId = location.hash.replace('#', '');
						var scrollToEl = document.getElementById(elId) || document.querySelector(`a[name="${elId}]"`);
						if (scrollToEl) {
							scrollToEl.scrollIntoView(true);
						}
						clearInterval(wait);
					}
				}, 500);
				setTimeout(() => {
					clearInterval(wait);
				}, 5000);
			}
		},
		initVhk() {
			// Inicializalja az osszes VHK webkomponenst
			const event = new CustomEvent('call-request-init');
			window.dispatchEvent(event);
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.allowAnchor && this.waitForFonts();
		});
		eventBus.$on('toggleAriaHidden', (hide) => {
			this.ariaHide = hide;
		});
		eventBus.$on('openOrsi', (orsiFeature) => {
			if (window.ORSI) {
				switch (orsiFeature) {
					case 'chat':
						window.ORSI.chat.openChat();
						break;
					case 'callrequest':
						window.ORSI.callrequest.openCallrequest();
						break;
					case 'writetous':
						window.ORSI.writetous.openWritetous();
						break;
					default:
				}
			}
		});
		document.addEventListener('tooltip-toggle', this.handleTooltipToggle);
		document.addEventListener('select-value-change', this.handleSelectValueChange);
		this.initVhk();
	},
	created() {
		eventBus.$on('heroTitleChange', (heroTitle) => {
			this.heroTitle = heroTitle;
		});
	},
	beforeDestroy() {
		document.removeEventListener('tooltip-toggle', this.handleTooltipToggle);
		document.removeEventListener('select-value-change', this.handleSelectValueChange);
	}
};
